import {
  all,
  fork,
  select,
  takeEvery,
  put,
  call,
  takeLatest,
} from "redux-saga/effects";
import { get, isNil, sortBy } from "lodash";
import moment from "moment";
import i18next from "i18next";
import { ActionCreator, AnyAction } from "redux";
import { push } from "connected-react-router";

import {
  getBanksByCountryTypes,
  GET_BANKS_BY_COUNTRY_ENDPOINT,
  getBankBranchesTypes,
  GET_BANK_BRANCHES_ENDPOINT,
  getPickupLocationsTypes,
  GET_PICKUP_LOCATIONS_ENDPOINT,
  createNewTransactionTypes,
  CREATE_NEW_TRANSACTION_ENDPOINT,
  TransactionOrigins,
  getMobileOperatorsByCountryTypes,
  getMobileOperatorByPhoneNumber,
  GET_MOBILE_OPERATORS_BY_COUNTRY_ENDPOINT,
  GET_MOBILE_OPERATOR_BY_PHONE_NUMBER_ENDPOINT,
  DUPLICATED_TRANSACTION_INTERVAL_IN_MINUTES,
  TransactionStatuses,
  VERIFY_AND_SUBMIT_TRANSACTION,
  getCellphoneProductsTypes,
  GET_CELLPHONE_PRODUCTS_ENDPOINT,
  getProcessorBanksTypes,
  GET_PROCESSOR_BANKS_ENDPOINT,
  PRINT_RECEIPT,
  GET_TRANSACTION_BY_QUERY_CODE_ENDPOINT,
  GET_CELLPHONE_PRODUCTS_UNAUTH_ENDPOINT,
  getBankByIfscTypes,
  GET_BANK_BY_IFSC_ENDPOINT,
  getCitiesByCountryTypes,
  GET_CITIES_BY_COUNTRY_ENDPOINT,
  getBranchesByCityTypes,
  GET_BRANCHES_BY_CITY_ENDPOINT,
  SET_TRANSFER_RECIPIENT,
  checkComplianceTypes,
  CHECK_COMPLIANCE_ENDPOINT,
  ComplianceRulesPriority,
  ComplianceRules,
  UserAccountStatuses,
  FundingSources,
  mpinValidateTypes,
  MPIN_VALIDATE_ENDPOINT,
  RESET_TRANSFER_AND_REVERT,
} from "../transfer.constants";
import {
  userDataSelector,
  latestTransactionSelector,
  userTransactionsSelector,
  userIdSelector,
  verficationDocumentsSelector,
} from "../../user/user.selectors";
import {
  agentIdSelector,
  sourceCountrySelector,
} from "../../organization/organization.selectors";
import { requestSagaHandler } from "../../../utils/sagas";
import environment from "../../../../../environment";
import { Spinners, Modals } from "../../ui/ui.constants";
import {
  API_MESSAGE,
  moneyTransferRoutes,
  TOKEN_KEY,
} from "../../../constants";
import {
  getBanksByCountryActions,
  getBankBranchesActions,
  getPickupLocationsActions,
  createNewTransactionActions,
  getMobileOperatorsByCountryActions,
  getMobileOperatorByPhoneNumberActions,
  getCellphoneProductsActions,
  getProcessorBanksActions,
  setReceiptDataAction,
  getBankByIfscActions,
  getCitiesByCountryActions,
  getBranchesByCityActions,
  setSplitTransactionAction,
  checkComplianceActions,
  storeComplianceRuleDataAction,
  otpFlushStateAction,
  mpinValidatedAction,
  resetTransferDetailsAction,
  otpSetCodeAction,
} from "../transfer.actions";
import {
  payoutTypeSelector,
  destinationCountrySelector,
  transferDetailsSelector,
  selectedRecipientSelector,
  operatorIdSelector,
  sourceAmountSelector,
  sourceCurrencySelector,
  promocodeSelector,
  complianceCheckSelector,
  creditCardSelector,
  selectOtpCode,
} from "../transfer.selectors";
import {
  IComplianceRule,
  IComplianceRuleWithPriority,
} from "../transfer.types";
import { SUCCESS, FAILURE } from "../../../utils/types/create-constants.types";
import {
  setDocumentUploadRedirectRoute,
  toggleModal,
} from "../../ui/ui.actions";
import { api } from "../../../utils/apiClient";
import toast from "../../../utils/toast";
import { ITransaction } from "../../user/user.types";
import introducerCodeService from "../../../services/introducer-code.service";

import transferVerificationSaga from "./transfer.verification.saga";
import calculatorSaga from "./transfer.calculator.saga";

const { EXTRA_DETAILS } = moneyTransferRoutes;

const {
  COMPLETE_SUCCESS,
  PENDING_CANCELLATION,
  PROCESSING,
  FS_IN_PROCESS,
  FS_CHARGED,
  COMPLIANCE_REVIEW,
  BLACKLIST_REVIEW,
  PRE_TXN,
  TELLER_APPROVED,
} = TransactionStatuses;

const getPriorityRule = (
  rules: IComplianceRule[],
): IComplianceRuleWithPriority => {
  try {
    const rulesWithPriority =
      rules &&
      rules.map((rule: IComplianceRule) => ({
        ...rule,
        priority: ComplianceRulesPriority[rule.action],
      }));
    return sortBy(rulesWithPriority, "priority")[0] || {};
  } catch (err) {
    throw err;
  }
};

function* createNewTransactionBodyGetter() {
  const { userId: senderId } = yield select(userDataSelector);
  const {
    payoutType,
    recipient: { recipientId },
    sourceCurrency,
    destinationCurrency,
    fundingSource,
    sourceOfFunds,
    useOfFunds,
    selectedPickupLocation,
    mobileOperator,
    reverse = false,
    cardNumber,
    selectedBankExternalCode,
    recipientExtraAddress,
    transactionStartTime,
  } = yield select(transferDetailsSelector);
  const operatorId = yield select(operatorIdSelector);
  const agentId = yield select(agentIdSelector);
  const transactionAmounts = yield getPriceAmounts();
  const promoCode = yield select(promocodeSelector);
  const otpCode = yield select(selectOtpCode);

  let advancedOptions = {};

  if (
    fundingSource === FundingSources.CHECKOUT_CREDIT_CARD ||
    fundingSource === FundingSources.CREDIT_CARD
  ) {
    const { newCard, ...cardDetails } = yield select(creditCardSelector);

    advancedOptions =
      cardDetails && cardDetails.number
        ? {
            number: cardDetails.number,
            expiry_month: cardDetails.expiryMonth,
            expiry_year: cardDetails.expiryYear,
          }
        : {};
  }

  return {
    data: {
      ...transactionAmounts,
      ...advancedOptions,
      ...(!isNil(otpCode) ? { transactionTwoFaCode: otpCode } : {}),
      organizationId: environment.organizationId,
      senderId,
      recipientId,
      reverse,
      promoCode,
      sendCurrency: sourceCurrency,
      receiveCurrency: destinationCurrency,
      payoutType,
      initiatedTime: transactionStartTime,
      pickupCityCode: get(selectedPickupLocation, "pickupCityCode"),
      fundingSource,
      operatorToUseId: operatorId,
      transactionOrigin: TransactionOrigins.WEB,
      transactionUserAgent: window.navigator.userAgent,
      sourceOfFunds,
      useOfFunds,
      cardNumber,
      depositingAgentId: agentId,
      cashPickupName: get(selectedPickupLocation, "cashPickupName"),
      omnexExternalCode:
        get(selectedPickupLocation, "externalCode") ||
        get(selectedBankExternalCode, "externalCode") ||
        get(mobileOperator, "externalCode"),
      pickupCode: get(selectedPickupLocation, "pickupCode"),
      pickupCityName: get(selectedPickupLocation, "pickupCityName"),
      mobileOperatorName: get(mobileOperator, "mobileOperatorName"),
      mobileOperatorCode: get(mobileOperator, "mobileOperatorCode"),
      introducerCode: introducerCodeService.introducerCode,
      ...(recipientExtraAddress && recipientExtraAddress.billingZipCode
        ? {
            senderZipCode: recipientExtraAddress.billingZipCode,
            senderCity: recipientExtraAddress.billingCity,
            senderHouseNumber: recipientExtraAddress.billingHouseNumber,
            senderAddress: recipientExtraAddress.billingAddress,
          }
        : {}),
    },
  };
}

function* getPriceAmounts() {
  const payoutType = yield select(payoutTypeSelector);

  switch (payoutType) {
    case "AIR_TIME": {
      const { cellphoneProducts } = yield select(transferDetailsSelector);

      return {
        amount: `${cellphoneProducts.sentAmount}`,
        amountDest: `${cellphoneProducts.receivedAmount}`,
        amountSenderToPay: `${cellphoneProducts.senderToPay}`,
      };
    }
    default: {
      const {
        sourceAmount,
        destinationAmount,
        priceResponse: { senderToPaySRC },
        reverse,
      } = yield select(transferDetailsSelector);

      return {
        amount: reverse ? `${destinationAmount}` : `${sourceAmount}`,
      };
    }
  }
}

function* getBanksByCountryBodyGetter() {
  const country = yield select(destinationCountrySelector);
  const operatorId = yield select(operatorIdSelector);
  const agentId = yield select(agentIdSelector);

  const data = {
    organizationId: environment.organizationId,
    country,
    operatorId,
    agentId,
  };

  return { data };
}

function* getOperatorsByCountryBodyGetter() {
  const country = yield select(destinationCountrySelector);
  const operatorId = yield select(operatorIdSelector);

  const data = {
    organizationId: environment.organizationId,
    country,
    operatorId,
  };

  return { data };
}

function* getOperatorByPhoneNumberBodyGetter() {
  const recipient = yield select(selectedRecipientSelector);

  const phone = recipient.phoneNumber.replace("+", "");
  console.log("phone", phone);

  const data = {
    number: phone,
  };

  return { data };
}

function* commonTransferInformation() {
  const country = yield select(destinationCountrySelector);
  const operatorId = yield select(operatorIdSelector);
  return {
    country,
    operatorId,
    organizationId: environment.organizationId,
  };
}

function* getBanksBranchesBodyGetter(bankCode: string) {
  const defaultInfo = yield commonTransferInformation();

  const data = {
    ...defaultInfo,
    bankCode,
  };

  return { data };
}

function* getBranchesByCityBodyGetter(city: string) {
  const defaultInfo = yield commonTransferInformation();

  const data = {
    ...defaultInfo,
    city,
  };

  return { data };
}

function* getPickupLocationsBodyGetter() {
  const country = yield select(destinationCountrySelector);
  const sourceCountry = yield select(sourceCountrySelector);
  const payoutType = yield select(payoutTypeSelector);
  const operatorId = yield select(operatorIdSelector);
  const agentId = yield select(agentIdSelector);

  const data = {
    payoutType,
    sourceCountry,
    country,
    operatorId,
    organizationId: environment.organizationId,
    agentId,
  };

  return { data };
}

function* isTransactionDuplicated() {
  const acceptedTransactionStatuses = [
    COMPLETE_SUCCESS,
    PENDING_CANCELLATION,
    PROCESSING,
    FS_IN_PROCESS,
    FS_CHARGED,
    COMPLIANCE_REVIEW,
    BLACKLIST_REVIEW,
    PRE_TXN,
    TELLER_APPROVED,
  ];
  const {
    recipientId: latestTransactionRecipientId,
    amountSent: latestTransactionAmount,
    date: latestTransactionDate,
    transactionStatus: latestTransactionStatus,
  } = yield select(latestTransactionSelector);

  const { sourceAmount, priceResponse } = yield select(transferDetailsSelector);
  const { recipientId } = yield select(selectedRecipientSelector);

  const latestTransactionTransactionDateLocal = moment
    .utc(latestTransactionDate)
    .local();

  const timeSinceLatestTransaction = moment
    .duration(moment().diff(latestTransactionTransactionDateLocal))
    .asMinutes();

  const isSameRecipient = latestTransactionRecipientId === recipientId;
  const isLessThen24HoursPassed =
    timeSinceLatestTransaction < DUPLICATED_TRANSACTION_INTERVAL_IN_MINUTES;
  const currentTransferAmount =
    sourceAmount || get(priceResponse, "amountSentSRC");
  const isSameAmount =
    parseFloat(currentTransferAmount) === parseFloat(latestTransactionAmount);

  return (
    isSameRecipient &&
    isLessThen24HoursPassed &&
    isSameAmount &&
    acceptedTransactionStatuses.includes(latestTransactionStatus)
  );
}

function* isMobileOrAirTimeTransfer() {
  const payoutType = yield select(payoutTypeSelector);
  return payoutType === "MOBILE_TOPUP" || payoutType === "AIR_TIME";
}

function* verifyAndSubmitTransaction() {
  const transactionDuplicated = yield call(isTransactionDuplicated);
  const mobileConfirmationPopup = true; // yield call(isMobileOrAirTimeTransfer);

  if (mobileConfirmationPopup) {
    yield put(toggleModal(Modals.MOBILE_CONFIRMATION_MODAL, true));
  }
  if (transactionDuplicated) {
    yield put(toggleModal(Modals.DUPLICATED_TRANSACTION_MODAL, true));
  }

  if (!mobileConfirmationPopup && !transactionDuplicated) {
    yield put(createNewTransactionActions.request());
  }
}

function* getCellphoneProductsBodyGetter(phoneNumber: string) {
  const operatorId = yield select(operatorIdSelector);
  const sourceCountry = yield select(sourceCountrySelector);
  const token = localStorage[TOKEN_KEY];

  return {
    url: token
      ? GET_CELLPHONE_PRODUCTS_ENDPOINT
      : GET_CELLPHONE_PRODUCTS_UNAUTH_ENDPOINT,
    data: {
      operatorId,
      organizationId: environment.organizationId,
      phoneNumber,
      sourceCountry,
    },
  };
}

const checkComplianceBodyGetter = (transaction: {
  amount: string;
  payoutType: string;
  recipientId: string;
  sendCurrency: string;
  senderId: string;
  transactionOrigin: string;
}) => ({
  data: {
    amount: transaction.amount,
    payoutType: transaction.payoutType,
    recipientId: transaction.recipientId,
    sendCurrency: transaction.sendCurrency,
    senderId: transaction.senderId,
    transactionOrigin: transaction.transactionOrigin,
  },
});

function* checkSplitTransactions() {
  const allTransactions = yield select(userTransactionsSelector);
  const successTransactions: string[] = [
    PRE_TXN,
    COMPLETE_SUCCESS,
    FS_CHARGED,
    TELLER_APPROVED,
    PROCESSING,
  ];
  const lastDayTransactions: ITransaction[] = allTransactions.filter(
    (tr: ITransaction) => {
      const diff = moment().diff(moment(tr.date), "minutes");
      return diff < 1440 && successTransactions.includes(tr.transactionStatus);
    },
  );
  const recipient = yield select(selectedRecipientSelector);
  const sourceAmount = yield select(sourceAmountSelector);
  const sourceCurrency = yield select(sourceCurrencySelector);
  const isSplit: boolean = lastDayTransactions.some(
    tr =>
      tr.recipientId === recipient.id &&
      tr.amountSent === sourceAmount &&
      tr.sourceCurrency === sourceCurrency,
  );
  yield put(setSplitTransactionAction(isSplit));
  yield put(push(moneyTransferRoutes.EXTRA_DETAILS));
}

function* handleComplianceRules(complianceRules: IComplianceRule[]) {
  const { MANUAL, REQUIRE_DOC_MANUAL, REQUIRE_DOC, BLOCK } = ComplianceRules;
  const complianceRule = getPriorityRule(complianceRules); // get the priority of the rule
  const userData = yield select(userDataSelector);

  yield put(storeComplianceRuleDataAction(complianceRule));

  const { FAILED, PENDING_REVIEW } = UserAccountStatuses;

  switch (complianceRule.action) {
    case BLOCK: {
      yield put(push(moneyTransferRoutes.LAST_TRANSACTIONS));
      toast.error({
        title: "Your transaction cannot be completed due to:",
        message: complianceRule.name,
      });
      break;
    }
    case MANUAL: {
      yield put(push(moneyTransferRoutes.EXTRA_DETAILS));
      break;
    }
    case REQUIRE_DOC:
    case REQUIRE_DOC_MANUAL: {
      const verficationDocuments = yield select(verficationDocumentsSelector);
      if (!isNil(verficationDocuments) && verficationDocuments.length < 1) {
        yield put(push(moneyTransferRoutes.EXTRA_DETAILS));
        break;
      }
      setDocumentUploadRedirectRoute(EXTRA_DETAILS);
      yield put(push(moneyTransferRoutes.UPLOAD_DOCUMENTS));
      break;
    }
    default: {
      switch (userData.complianceStatus) {
        case FAILED:
          yield put(push(moneyTransferRoutes.ACCOUNT_BLOCKED));
          break;
        case PENDING_REVIEW:
          yield put(push(moneyTransferRoutes.ACCOUNT_UNDER_REVIEW));
          break;
        default:
          yield put(push(moneyTransferRoutes.TRANSACTION_CANCELLED));
          break;
      }
      break;
    }
  }
}

function* handleComplianceCheck() {
  const cCHeck = yield select(complianceCheckSelector);
  if (!cCHeck.passedChecks) {
    yield handleComplianceRules(cCHeck.failedComplianceRules);
  } else {
    yield checkSplitTransactions();
  }
}

function* checkCompliance() {
  const userData = yield select(userDataSelector);
  const payoutType = yield select(payoutTypeSelector);
  if (payoutType === "AIR_TIME") {
    yield checkSplitTransactions();
    return;
  }
  const recipient = yield select(selectedRecipientSelector);

  // if (
  //   userData.country === "NL" ||
  //   userData.country === "DE" ||
  //   userData.country === "IT"
  // ) {
  //   if (recipient.blackListed) {
  //     yield toast.error({
  //       title: "Recipient is blocked",
  //       message: recipient.blockedMessage,
  //     });
  //     yield put(resetTransferDetailsAction());
  //     yield put(push(moneyTransferRoutes.LAST_TRANSACTIONS));
  //     return;
  //   }
  // }

  const sourceAmount = yield select(sourceAmountSelector);
  const sourceCurrency = yield select(sourceCurrencySelector);
  const userId = yield select(userIdSelector);

  const payload = {
    amount: sourceAmount,
    payoutType,
    sendCurrency: sourceCurrency,
    senderId: userId,
    recipientId: recipient.recipientId,
    transactionOrigin: "WEB",
  };

  yield put(checkComplianceActions.request(payload));
}

function* resetOtpState() {
  yield put(otpFlushStateAction());
}

const getBanksFork = fork(requestSagaHandler.post, {
  paramsGetter: getBanksByCountryBodyGetter,
  actions: getBanksByCountryActions,
  types: getBanksByCountryTypes,
  url: GET_BANKS_BY_COUNTRY_ENDPOINT,
  spinner: Spinners.LOADING_BANKS_SPINNER,
});

const getCitiesByCountryFork = fork(requestSagaHandler.post, {
  paramsGetter: getBanksByCountryBodyGetter,
  actions: getCitiesByCountryActions,
  types: getCitiesByCountryTypes,
  url: GET_CITIES_BY_COUNTRY_ENDPOINT,
  spinner: Spinners.LOADING_BANKS_SPINNER,
});

const getBranchesByCityFork = fork(requestSagaHandler.post, {
  paramsGetter: getBranchesByCityBodyGetter,
  actions: getBranchesByCityActions,
  types: getBranchesByCityTypes,
  url: GET_BRANCHES_BY_CITY_ENDPOINT,
  spinner: Spinners.LOADING_BANKS_SPINNER,
});

const getBankBranchesFork = fork(requestSagaHandler.post, {
  paramsGetter: getBanksBranchesBodyGetter,
  actions: getBankBranchesActions,
  types: getBankBranchesTypes,
  url: GET_BANK_BRANCHES_ENDPOINT,
  spinner: Spinners.LOADING_BANK_BRANCHES_SPINNER,
});

const getPickupLocationsFork = fork(requestSagaHandler.post, {
  paramsGetter: getPickupLocationsBodyGetter,
  actions: getPickupLocationsActions,
  types: getPickupLocationsTypes,
  url: GET_PICKUP_LOCATIONS_ENDPOINT,
});

const createNewTransactionFork = fork(requestSagaHandler.post, {
  paramsGetter: createNewTransactionBodyGetter,
  actions: createNewTransactionActions,
  types: createNewTransactionTypes,
  url: CREATE_NEW_TRANSACTION_ENDPOINT,
  spinner: Spinners.TRANSACTION_NEW_SPINNER,
  toastMessage: {
    FAILURE: API_MESSAGE,
  },
});

const getMobileOperatorsByCountryFork = fork(requestSagaHandler.post, {
  paramsGetter: getOperatorsByCountryBodyGetter,
  actions: getMobileOperatorsByCountryActions,
  types: getMobileOperatorsByCountryTypes,
  url: GET_MOBILE_OPERATORS_BY_COUNTRY_ENDPOINT,
  spinner: Spinners.LOADING_BANKS_SPINNER,
  toastMessage: () => ({
    FAILURE: i18next.t("service_unavailable"),
  }),
});

const getMobileOperatorByPhoneNumberFork = fork(requestSagaHandler.post, {
  paramsGetter: getOperatorByPhoneNumberBodyGetter,
  actions: getMobileOperatorByPhoneNumberActions,
  types: getMobileOperatorByPhoneNumber,
  url: GET_MOBILE_OPERATOR_BY_PHONE_NUMBER_ENDPOINT,
  spinner: Spinners.LOADING_MOBILE_OPERATOR_SPINNER,
  toastMessage: () => ({
    FAILURE: i18next.t("telco_identification_api_service_unavailable"),
  }),
});

const getBankByIfscFork = fork(requestSagaHandler.post, {
  // paramsGetter: getBanksByCountryBodyGetter,
  actions: getBankByIfscActions,
  types: getBankByIfscTypes,
  url: GET_BANK_BY_IFSC_ENDPOINT,
  spinner: Spinners.IFSC_SEARCH_SPINNER,
  toastMessage: () => ({
    FAILURE: i18next.t("service_unavailable"),
  }),
});

const getCellphoneProductsFork = fork(requestSagaHandler.post, {
  paramsGetter: getCellphoneProductsBodyGetter,
  actions: getCellphoneProductsActions,
  types: getCellphoneProductsTypes,
  url: GET_CELLPHONE_PRODUCTS_ENDPOINT,
  spinner: Spinners.LOADING_PAYOUT_TYPE_DETAILS_SPINNER,
  toastMessage: () => ({
    FAILURE: i18next.t("service_unavailable"),
  }),
});

const getProcessorBanksFork = fork(requestSagaHandler.post, {
  paramsGetter: () => ({
    data: {
      organizationId: environment.organizationId,
      // сountryCode: "NL",
      // origin: "WEB",
    },
  }),
  actions: getProcessorBanksActions,
  types: getProcessorBanksTypes,
  url: GET_PROCESSOR_BANKS_ENDPOINT,
  spinner: Spinners.LOADING_PAYOUT_TYPE_DETAILS_SPINNER,
});

const checkComplianceFork = fork(requestSagaHandler.post, {
  paramsGetter: checkComplianceBodyGetter,
  actions: checkComplianceActions,
  types: checkComplianceTypes,
  url: CHECK_COMPLIANCE_ENDPOINT,
});

const mpinValidateFork = fork(requestSagaHandler.post, {
  paramsGetter: (code: string) => ({ data: { code } }),
  actions: mpinValidatedAction,
  types: mpinValidateTypes,
  url: MPIN_VALIDATE_ENDPOINT,
});

function* printReceiptSaga({ payload }: ReturnType<ActionCreator<AnyAction>>) {
  try {
    const { data } = yield call(
      api.post,
      GET_TRANSACTION_BY_QUERY_CODE_ENDPOINT,
      {
        organizationId: environment.organizationId,
        ...payload,
      },
    );

    if (data.status === "FAIL") {
      throw null;
    }

    yield put(setReceiptDataAction(data));
  } catch {
    yield put(setReceiptDataAction(null));
    yield toast.error({ message: i18next.t("failed_to_get_receipt") });
  }
}

function* resetTranferAndRevert(payload?: any) {
  yield all(
    payload && payload.errorText
      ? [
          put(resetTransferDetailsAction()),
          put(push(moneyTransferRoutes.LAST_TRANSACTIONS)),
          toast.error({ message: payload.errorText }),
        ]
      : [
          put(resetTransferDetailsAction()),
          put(push(moneyTransferRoutes.LAST_TRANSACTIONS)),
        ],
  );
}

export default function* transferSaga() {
  yield all([
    calculatorSaga,
    transferVerificationSaga,

    getBanksFork,
    getCitiesByCountryFork,
    getBranchesByCityFork,
    getProcessorBanksFork,
    getBankBranchesFork,
    getBankByIfscFork,
    getPickupLocationsFork,
    checkComplianceFork,
    mpinValidateFork,
    takeEvery(VERIFY_AND_SUBMIT_TRANSACTION, verifyAndSubmitTransaction),
    getMobileOperatorsByCountryFork,
    getMobileOperatorByPhoneNumberFork,
    createNewTransactionFork,
    getCellphoneProductsFork,
    takeLatest(PRINT_RECEIPT, printReceiptSaga),
    takeEvery(SET_TRANSFER_RECIPIENT, checkCompliance),
    takeEvery(RESET_TRANSFER_AND_REVERT, resetTranferAndRevert),
    takeEvery(
      [checkComplianceTypes[SUCCESS], checkComplianceTypes[FAILURE]],
      handleComplianceCheck,
    ),
    takeEvery(
      [createNewTransactionTypes[SUCCESS], createNewTransactionTypes[FAILURE]],
      resetOtpState,
    ),
  ]);
}
